import { render, staticRenderFns } from "./EmptyPage.vue?vue&type=template&id=2c66ee79&"
import script from "./EmptyPage.vue?vue&type=script&lang=js&"
export * from "./EmptyPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports