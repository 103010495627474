<template>
	<div class="text-info" :class="elClass">
		<h1><b-icon-cloud-slash></b-icon-cloud-slash></h1>
		<h4>Data not available</h4>
		<p>There is no data to show you right now!</p>
	</div>
</template>

<script>
export default {
	name: "EmptyPage",
	props: ['elClass']
}
</script>