<template>
	<div>
		<div class="row">

			<!-- CLOSE LM-AWB COUNT CARD -->
			<div class="col-lg-3 col-md-4 col-sm-6">
				<div class="card-counter info">
					<b-icon-bag class="icon"></b-icon-bag>
					<span v-if="isBusy.openawb" class="count-numbers"><b-spinner class="ml-1"></b-spinner></span>
					<span v-else class="count-numbers">{{openAWB.length}}</span>
					<span class="count-name">In scanned LM AWB</span>
					<div class="footer" @click="openTab('openawb')">
						View details <b-icon-arrow-right-circle class="float-right" :rotate="type=='openawb'?90:0"></b-icon-arrow-right-circle>
					</div>
				</div>
			</div>


			<!-- OPEN LM-AWB COUNT CARD -->
			<div class="col-lg-3 col-md-4 col-sm-6">
				<div class="card-counter success">
					<b-icon-bag-check class="icon"></b-icon-bag-check>
					<span v-if="isBusy.closeawb" class="count-numbers"><b-spinner class="ml-1"></b-spinner></span>
					<span v-else class="count-numbers">{{closedAWB.length}}</span>
					<span class="count-name">Out scanned LM AWB</span>
					<div class="footer" @click="openTab('closeawb')">
						View details <b-icon-arrow-right-circle class="float-right" :rotate="type=='closeawb'?90:0"></b-icon-arrow-right-circle>
					</div>
				</div>
			</div>


			<!-- CURRENT INVENTORY COUNT CARD -->
			<div class="col-lg-3 col-md-4 col-sm-6">
				<div class="card-counter danger">
					<b-icon-bag-x class="icon"></b-icon-bag-x>
					<span v-if="isBusy.inventoryawb" class="count-numbers"><b-spinner class="ml-1"></b-spinner></span>
					<span v-else class="count-numbers">{{inventories.length}}</span>
					<span class="count-name">Current Stock</span>
					<div class="footer" @click="openTab('inventoryawb')">
						View details <b-icon-arrow-right-circle class="float-right" :rotate="type=='inventoryawb'?90:0"></b-icon-arrow-right-circle>
					</div>
				</div>
			</div>
			
		</div>


		<div class="row mt-4">
			
			<!-- OPEN LM-AWB TABLE -->
			<div v-if="type == 'openawb'" class="col-sm-12">
				<div>
					<div class="row">
						<div class="col-sm-12">
							<h4 class="float-left">Open LM-AWB</h4>
							<div class="float-right">
								<date-range-picker
									opens="left"
									v-model="dateRange"
									class="ml-2"
									@update="getItems"
									:locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
								></date-range-picker>
							</div>
							<download-excel
								v-if="openAWB.length>0"
								:data="openAWB"
								class="btn btn-info float-right"
								worksheet="Open LM-AWB"
								name="open-lm-awb.csv"
								type="csv"
								:class="{'disabled': downloading}"
								:before-generate="downloadStart"
								:before-finish="downloadFinish"
								:stringifyLongNum="true"
							>
								Download 
								<b-spinner v-if="downloading" label="Spinning" small></b-spinner>
								<b-icon-cloud-download v-else></b-icon-cloud-download>
							</download-excel>
						</div>
					</div>
					<div v-if="openAWB.length>0">
						<b-table hover :per-page="perPage" :current-page="currentPage" bordered head-variant="light" :items="openAWB"></b-table>
						<b-pagination
							v-if="openAWB.length > perPage"
							v-model="currentPage"
							:total-rows="openAWB.length"
							:per-page="perPage"
							class="float-right"
						></b-pagination>
					</div>
					<div v-else-if="!isBusy.openawb" class="text-center">
						<EmptyPageVue />
					</div>
					<div v-else class="text-center small">
						<b-spinner></b-spinner>
					</div>
				</div>
			</div>


			<!-- CLOSE LM-AWB TABLE -->
			<div v-if="type == 'closeawb'" class="col-sm-12">
				<div>
					<div class="row">
						<div class="col-sm-12">
							<h4 class="float-left">Closed LM-AWB</h4>
							<div class="float-right">
								<date-range-picker
									opens="left"
									v-model="dateRange"
									class="ml-2"
									@update="getItems"
									:locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
								></date-range-picker>
							</div>
							<download-excel
								v-if="closedAWB.length>0"
								:data="closedAWB"
								class="btn btn-info float-right"
								worksheet="closed LM-AWB"
								name="closed-lm-awb.csv"
								type="csv"
								:class="{'disabled': downloading}"
								:before-generate="downloadStart"
								:before-finish="downloadFinish"
								:stringifyLongNum="true"
							>
								Download 
								<b-spinner v-if="downloading" label="Spinning" small></b-spinner>
								<b-icon-cloud-download v-else></b-icon-cloud-download>
							</download-excel>
						</div>
					</div>
					<div v-if="closedAWB.length>0">
						<b-table hover :per-page="perPage" :current-page="currentPage" bordered head-variant="light" :items="closedAWB"></b-table>
						<b-pagination
							v-if="closedAWB.length > perPage"
							v-model="currentPage"
							:total-rows="closedAWB.length"
							:per-page="perPage"
							class="float-right"
						></b-pagination>
					</div>
					<div v-else-if="!isBusy.closeawb" class="text-center">
						<EmptyPageVue />
					</div>
					<div v-else class="text-center small">
						<b-spinner></b-spinner>
					</div>
					
				</div>
			</div>


			<!-- CURRENT INVENTORY TABLE -->
			<div v-if="type == 'inventoryawb'" class="col-sm-12">
				<div>
					<div class="row">
						<div class="col-sm-12">
							<h4 class="float-left">Current inventory</h4>
							<download-excel
								v-if="inventories.length>0"
								:data="inventories"
								class="btn btn-info float-right mb-2"
								worksheet="Current Inventory LM-AWB"
								name="current-inventory-lm-awb.csv"
								type="csv"
								:class="{'disabled': downloading}"
								:before-generate="downloadStart"
								:before-finish="downloadFinish"
								:stringifyLongNum="true"
							>
								Download 
								<b-spinner v-if="downloading" label="Spinning" small></b-spinner>
								<b-icon-cloud-download v-else></b-icon-cloud-download>
							</download-excel>
						</div>
					</div>

					<div v-if="inventories.length>0">
						<b-table hover :per-page="perPage" :current-page="currentPage" bordered head-variant="light" :items="inventories"></b-table>
						<b-pagination
							v-if="inventories.length > perPage"
							v-model="currentPage"
							:total-rows="inventories.length"
							:per-page="perPage"
							class="float-right"
						></b-pagination>
					</div>
					<div v-else-if="!isBusy.inventoryawb" class="text-center">
						<EmptyPageVue />
					</div>
					<div v-else class="text-center small">
						<b-spinner></b-spinner>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import JsonExcel from "vue-json-excel";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import EmptyPageVue from './layout/EmptyPage.vue';

export default {
	name: "Inventory",
	title: "Inventory",
	components: {
		'downloadExcel': JsonExcel,
		DateRangePicker,
		EmptyPageVue
	},
	data() {
		return {
			perPage: 7,
			currentPage: 1,
			openAWB: [],
			closedAWB: [],
			inventories: [],
			downloading: false,
			isBusy: {
				openawb: false,
				closeawb: false,
				inventoryawb: false
			},
			type: 'openawb',
			dateRange: {
				startDate: new Date(),
				endDate: new Date()
			}
		}
	},
	mounted() {
		this.getItems()
	},
	methods: {
		openTab(tab) {
			this.type = tab
			this.getItems()
		},
		// GET INVETORY COUTS...
		async getItems() {
			try {
				
				this.isBusy[this.type] = true
				var options = {
					year: "numeric",
					month: "2-digit",
					day: "2-digit"
				}
				const startDate = this.dateRange.startDate.toLocaleString('sv-SE', options)
				const endDate = this.dateRange.endDate.toLocaleString('sv-SE', options)
				
				const res = await this.axios.get(`/inventory/${this.type}?startDate=${startDate}&endDate=${endDate}`)
				if(res.data.success) {
					if(res.data.openAwb) {
						this.openAWB = res.data.openAwb.map(i => {
							return {
								...i,
								created: `${this.$options.filters.longdate(i.created)}, ${this.$options.filters.time(i.created)}`
							}						
						})
					}
					
					if(res.data.closeAwb) {
						this.closedAWB = res.data.closeAwb.map(i => {
							return {
								...i,
								created: `${this.$options.filters.longdate(i.created)}, ${this.$options.filters.time(i.created)}`,
								arrival_date: i.arrival_date ? this.$options.filters.longdate(i.arrival_date) : ''
							}						
						})
					}
					
					if(res.data.inventory) {
						this.inventories = res.data.inventory.map(i => {
							return {
								...i,
								created: `${this.$options.filters.longdate(i.created)}, ${this.$options.filters.time(i.created)}`
							}						
						})
					}
				}
				this.isBusy[this.type] = false
			} catch (err) {
				this.isBusy[this.type] = false
				console.error(err)
			}
		},

		downloadStart() {
			this.downloading = true
		},
		downloadFinish() {
			this.downloading = false
		}
	}
}
</script>

<style lang="scss" scoped>
.card-counter {
	box-shadow: 2px 2px 10px #dadada;
	background-color: #fff;
	margin-bottom: 10px;
	border-radius: 5px;
	transition: 0.3s linear all;

	.footer {
		background: #0000001a;
		padding: 5px 10px;
		transition: all .5s;
		cursor: pointer;
		color: #ffffff94;

		&:hover {
			color: #fff;
		}
	}
}
.footer .b-icon.bi {
	margin-top: 5px;
}

.card-counter:hover {
	box-shadow: 4px 4px 20px #dadada;
	transition: 0.3s linear all;
}

.card-counter.primary {
	background-color: #007bff;
	color: #fff;
}

.card-counter.danger {
	background-color: #ef5350;
	color: #fff;
}

.card-counter.success {
	background-color: #66bb6a;
	color: #fff;
}

.card-counter.info {
	background-color: #26c6da;
	color: #fff;
}

.card-counter .icon {
	font-size: 5em;
	opacity: 0.2;
	margin: 10px;
	color: #ffffffc7;;
}

.card-counter .count-numbers {
	position: absolute;
	right: 35px;
	top: 20px;
	font-size: 32px;
	display: block;
}

.card-counter .count-name {
	position: absolute;
	right: 35px;
	top: 65px;
	font-style: italic;
	text-transform: capitalize;
	opacity: 0.7;
	display: block;
	font-size: 18px;
}
</style>